'use strict';

var subNav = document.getElementById('subNav');
var topBar = document.getElementById('topBar');

function evalSubNav() {
  if (!subNav) return;

  if ((subNav.clientHeight + topBar.clientHeight + 30) <= window.innerHeight) {
    subNav.classList.add('subNav--sticky');
  } else {
    subNav.classList.remove('subNav--sticky');
  }
}

window.addEventListener('resize', evalSubNav);

evalSubNav();